import Vue from 'vue';
import Vuex from 'vuex';

import usersModule from './modules/users/module';
import emailsModule from './modules/emails/module';
import formsModule from './modules/forms/module';
import feedbacksModule from './modules/feedbacks/module';
import pdpModule from './modules/pdp/module';
import workflowsModule from './modules/workflows/module';
import careerDevelopmentModule from './modules/careerDevelopment/module';
import dashboardModule from './modules/dashboard/module';
import errorLogModule from './modules/errorLog/module';
import notificationsModule from './modules/notifications/module';

import notificationService from '../services/NotificationService';
import sessionService from '../services/SessionService';
import themeService from '@/services/ThemeService';
import workflowsService from '@/services/WorkflowsService';
import localeService from '@/services/LocaleService';

import { access } from '@/constants/access.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentOrganization: null,
    currentUser: null,
    languageKey: 'de-DE',
    notifications: [],
    workflowSettings: [],
    dashboardSettings: {},
    darkMode: false,
    authorizationProgress: 20,
    authorizationInfo: 'Sitzung wird eingerichtet',
    idUser: null,
    userManager: null
  },
  getters: {
    currentOrganization: (state) => state.currentOrganization,
    currentUser: (state) => state.currentUser,
    languageKey: (state) => state.languageKey,
    notifications: (state) => state.notifications,
    newNotificationsCount: (state) => {
      if (!state.notifications || !Array.isArray(state.notifications)) {
        return 0;
      }

      return state.notifications.filter((x) => !x.seen).length;
    },

    dashboardSettings: (state) => state.dashboardSettings,
    authorizationProgress: (state) => state.authorizationProgress,
    authorizationInfo: (state) => state.authorizationInfo,
    workflowSettings: (state) => state.workflowSettings,
    getAccess: (state) => (role) => {
      if (!state.currentUser) {
        return access.NONE;
      }

      if (state.currentUser.userRoles.includes('dpt.full_admin')) {
        return access.READ_WRITE;
      }

      let roleAccess = access.NONE;
      if (state.currentUser.userRoles.includes(`dpt.${role}.read`)) {
        roleAccess = access.READ;
      }

      if (state.currentUser.userRoles.includes(`dpt.${role}.write`)) {
        roleAccess =
          roleAccess === access.READ ? access.READ_WRITE : access.WRITE;
      }

      return roleAccess;
    }
  },
  mutations: {
    setAuthorization(state, params) {
      state.authorizationProgress = params.progress;
      if (params.info != null && params.info !== '') {
        state.authorizationInfo = params.info;
      }
    },
    initializeSession(state, session) {
      state.currentOrganization = session.currentOrganization;
      state.currentUser = session.currentUser;
      state.languageKey = session.languageKey;
      state.dashboardSettings = session.dashboardSettings;
      state.workflowSettings = session.workflows;
    },
    setLanguage(state, languageKey) {
      state.languageKey = languageKey;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setNotificationSeen(state, notificationId) {
      const notification = state.notifications.filter(
        (x) => x.id === notificationId
      );
      if (notification) {
        notification[0].seen = true;
      }
    },
    setWorkflowSettings(state, workflowSettings) {
      state.workflowSettings = workflowSettings;
    },

    setDarkMode(state, value) {
      state.darkMode = value;
    }
  },
  actions: {
    async loadLocale(context, slug) {
      return await localeService.getLocaleSettings(slug);
    },

    async setAuthorization({ commit }, params) {
      await commit('setAuthorization', params);
    },
    async initializeSession({ commit, dispatch }) {
      var session = await sessionService.initialize();

      await commit('setAuthorization', {
        progress: 75,
        info: 'Sitzung wird gespeichert'
      });

      await commit('initializeSession', session);

      await commit('setAuthorization', {
        progress: 100,
        info: 'Erfolgreich!'
      });

      commit('forms/setCustomSettings', session.customFormSettings);

      dispatch('fetchNotifications');

      if (session.currentUser.isAdmin) {
        dispatch('forms/listForms');
      }
    },
    async setLanguage({ state, commit }, languageKey) {
      await commit('setLanguage', languageKey);
      await sessionService.setLanguage(state.currentUser.userId, languageKey);
    },

    async fetchNotifications({ commit, state }) {
      if (state.notifications !== null && state.notifications.length > 0) {
        return;
      }

      if (!state.currentUser) {
        return;
      }

      var notifications = await notificationService.getNotifications(
        state.currentUser.id
      );

      await commit('setNotifications', notifications);
    },
    async setNotificationSeen({ commit }, notificationId) {
      notificationService.markAsRead(notificationId);
      await commit('setNotificationSeen', notificationId);
    },
    async fetchWorkflowSettings({ commit }) {
      var workflowSettings = await workflowsService
        .getWorkflows()
        .then((x) => x.settings);
      await commit('setWorkflowSettings', workflowSettings);
    },

    async getThemeColors(context, slug) {
      return await themeService.getTheme(slug);
    },

    async impersonateUser(context, user) {
      await sessionService.impersonateUser(user.id);
    },

    async unimpersonateUser() {
      await sessionService.unimpersonateUser();
    }
  },
  modules: {
    users: usersModule,
    emails: emailsModule,
    forms: formsModule,
    feedbacks: feedbacksModule,
    pdp: pdpModule,
    workflows: workflowsModule,
    careerDevelopment: careerDevelopmentModule,
    dashboard: dashboardModule,
    errorLog: errorLogModule,
    notifications: notificationsModule
  }
});
